require('dotenv').config();

const prod = {
	url: {
		API_URL: 'https://app.languagejourneys.com:8443/api/',
		UPLOAD_URL: 'https://app.languagejourneys.com:8443/uploads/',
	},
};
const dev = {
	url: {
		API_URL: 'http://127.0.0.1:8000/api/',
		UPLOAD_URL: 'http://127.0.0.1:8000/uploads/',
	},
};
export const conf = process.env.NODE_ENV === 'development' ? dev : prod;
