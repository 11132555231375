import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	Container,
	Table,
	Spinner,
	Input,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Row,
	Pagination,
	PaginationItem,
	PaginationLink,
} from 'reactstrap';
import { Edit, Trash2, Save, X } from 'react-feather';
import TableTextCell from '../../components/TableTextCell';
import { ApiService } from '../../services/apiService';

const Translations = () => {
	const history = useHistory();
	const { session } = useSelector((state) => state.session);

	useEffect(() => {
		ApiService.getTranslations(session)
			.then((response) => response.json())
			.then((data) => {
				if (data && data.message === 'Unauthenticated.') {
					history.push('/admin');

					return;
				}

				console.log(data.data);
				setTranslations(data.data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	const [translations, setTranslations] = useState([]);
	const [currentId, setCurrentId] = useState(-1);
	const [currentTranslation, setCurrentTranslation] = useState({});
	const [searchValue, setSearchValue] = useState({
		en: '',
		es: '',
		fr: '',
		de: '',
	});
	const [page, setPage] = useState(1);

	const [open, setOpen] = useState(false);

	const toggle = () => {
		setOpen(!open);
	};

	const handleEdit = (id, index) => {
		console.log('edit');
		setCurrentId(index);

		const curTranslation = translations.filter(
			(trnaslation) => trnaslation.id == id
		);

		if (curTranslation.length > 0) setCurrentTranslation(curTranslation[0]);
	};

	const handleDelete = (id) => {
		console.log(id);

		const curTranslation = translations.filter(
			(trnaslation) => trnaslation.id == id
		);

		if (curTranslation.length > 0) setCurrentTranslation(curTranslation[0]);

		toggle();
	};

	const handleSave = (id) => {
		console.log(currentTranslation);
		ApiService.updateTranslation(session, id, currentTranslation)
			.then((data) => {
				console.log(data);

				const curTranslation = translations.filter(
					(trnaslation) => trnaslation.id == id
				);
				if (curTranslation.length > 0) {
					const index = translations.indexOf(curTranslation[0]);
					const tmpTranslations = translations.slice();
					tmpTranslations[index].en = currentTranslation.en;
					tmpTranslations[index].es = currentTranslation.es;
					tmpTranslations[index].fr = currentTranslation.fr;
					tmpTranslations[index].de = currentTranslation.de;
					setTranslations(tmpTranslations);
				}
			})
			.catch((err) => {
				console.error(err);
			});

		setCurrentId(-1);
	};

	const handleCancel = () => {
		setCurrentId(-1);
	};

	const handleConfirm = () => {
		console.log(currentTranslation.id);
		ApiService.deleteTranslation(session, currentTranslation.id)
			.then((data) => {
				console.log(data);
				let tmpTranslations = translations.slice();
				const curTranslation = translations.filter(
					(trnaslation) => trnaslation.id == currentTranslation.id
				);
				if (curTranslation.length > 0) {
					const index = tmpTranslations.indexOf(curTranslation[0]);
					console.log(curTranslation[0]);
					console.log(index);
					if (index > -1) {
						tmpTranslations.splice(index, 1);
					}
				}
				console.log('Delete');
				console.log(tmpTranslations);

				setTranslations(tmpTranslations);
			})
			.catch((err) => {
				console.error(err);
			});

		toggle();
	};

	const filteredTranslations =
		translations.length > 0
			? translations.filter(
					(translation) =>
						translation.en
							.toLowerCase()
							.includes(searchValue.en.toLowerCase()) &&
						translation.es
							.toLowerCase()
							.includes(searchValue.es.toLowerCase()) &&
						translation.fr
							.toLowerCase()
							.includes(searchValue.fr.toLowerCase()) &&
						translation.de
							.toLowerCase()
							.includes(searchValue.de.toLowerCase())
			  )
			: new Array();

	let pageCount = 0;
	if (filteredTranslations.length > 0) {
		pageCount = parseInt(filteredTranslations.length / 40) + 1;
	}

	const handlePrev = () => {
		if (page == 1) return;

		setPage(page - 1);
	};

	const handleNext = () => {
		if (page == pageCount) return;

		setPage(page + 1);
	};

	const handlePage = (index) => {
		setPage(index);
	};

	if (translations.length == 0) {
		return (
			<Container
				fluid
				className='vh-50 d-flex justify-content-center align-items-center'>
				<Spinner color='primary' size='lg' className='mr-2' />
			</Container>
		);
	}

	return (
		<Container fluid className='p-0'>
			<div className='translations_header'>
				<h1 className='h3 mb-3'>Translations</h1>
				<button>Add</button>
			</div>
			<Table bordered style={{ tableLayout: 'fixed' }}>
				<thead>
					<tr>
						<th className='text-center' style={{ width: 45 }}>
							No
						</th>
						<th className='text-center' style={{ width: '22%' }}>
							English
						</th>
						<th className='text-center' style={{ width: '22%' }}>
							Spanish
						</th>
						<th className='text-center' style={{ width: '22%' }}>
							French
						</th>
						<th className='text-center' style={{ width: '22%' }}>
							German
						</th>
						<th className='text-center' style={{ width: 85 }}>
							Actions
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td></td>
						<td>
							<Input
								name='Search-EN'
								placeholder='Search...'
								onChange={(val) => {
									setSearchValue({
										...searchValue,
										en: val.target.value,
									});
									setCurrentId(-1);
									setPage(1);
								}}
								value={searchValue.en}
							/>
						</td>
						<td>
							<Input
								name='Search-ES'
								placeholder='Search...'
								onChange={(val) => {
									setSearchValue({
										...searchValue,
										es: val.target.value,
									});
									setCurrentId(-1);
									setPage(1);
								}}
								value={searchValue.es}
							/>
						</td>
						<td>
							<Input
								name='Search-FR'
								placeholder='Search...'
								onChange={(val) => {
									setSearchValue({
										...searchValue,
										fr: val.target.value,
									});
									setCurrentId(-1);
									setPage(1);
								}}
								value={searchValue.fr}
							/>
						</td>
						<td>
							<Input
								name='Search-DE'
								placeholder='Search...'
								onChange={(val) => {
									setSearchValue({
										...searchValue,
										de: val.target.value,
									});
									setCurrentId(-1);
									setPage(1);
								}}
								value={searchValue.de}
							/>
						</td>
						<td></td>
					</tr>

					{filteredTranslations
						.slice((page - 1) * 40, Math.min(page * 40))
						.map((translation, index) => {
							if (index == currentId) {
								return (
									<tr key={index}>
										<td className='text-center'>
											{translation.id}
										</td>
										<td>
											<Input
												name='EN'
												onChange={(val) =>
													setCurrentTranslation({
														...currentTranslation,
														en: val.target.value,
													})
												}
												value={currentTranslation.en}
											/>
										</td>
										<td>
											<Input
												name='ES'
												value={currentTranslation.es}
												onChange={(val) =>
													setCurrentTranslation({
														...currentTranslation,
														es: val.target.value,
													})
												}
											/>
										</td>
										<td>
											<Input
												name='FR'
												value={currentTranslation.fr}
												onChange={(val) =>
													setCurrentTranslation({
														...currentTranslation,
														fr: val.target.value,
													})
												}
											/>
										</td>
										<td>
											<Input
												name='DE'
												value={currentTranslation.de}
												onChange={(val) =>
													setCurrentTranslation({
														...currentTranslation,
														de: val.target.value,
													})
												}
											/>
										</td>
										<td className='table-action text-center'>
											<Row
												className='d-flex justify-content-around'
												style={{
													margin: 'auto',
													width: 60,
												}}>
												<Button
													className='p-0'
													color='none'
													onClick={() =>
														handleSave(
															translation.id
														)
													}>
													<Save
														className='align-middle'
														size={18}
													/>
												</Button>
												<Button
													className='p-0'
													color='none'
													onClick={() =>
														handleCancel()
													}>
													<X
														className='align-middle'
														size={18}
													/>
												</Button>
											</Row>
										</td>
									</tr>
								);
							} else {
								return (
									<tr key={index}>
										<td className='text-center'>
											{translation.id}
										</td>
										<td>
											<TableTextCell>
												{translation.en}
											</TableTextCell>
										</td>
										<td>
											<TableTextCell>
												{translation.es}
											</TableTextCell>
										</td>
										<td>
											<TableTextCell>
												{translation.fr}
											</TableTextCell>
										</td>
										<td>
											<TableTextCell>
												{translation.de}
											</TableTextCell>
										</td>
										<td className='table-action text-center'>
											<Row
												className='d-flex justify-content-around'
												style={{
													margin: 'auto',
													width: 60,
												}}>
												<Button
													className='p-0'
													color='none'
													onClick={() =>
														handleEdit(
															translation.id,
															index
														)
													}>
													<Edit
														className='align-middle'
														size={18}
													/>
												</Button>
												<Button
													className='p-0'
													color='none'
													onClick={() =>
														handleDelete(
															translation.id
														)
													}>
													<Trash2
														className='align-middle'
														size={18}
													/>
												</Button>
											</Row>
										</td>
									</tr>
								);
							}
						})}
				</tbody>
			</Table>

			<Row className='justify-content-center'>
				<Pagination aria-label='Page navigation' size='lg'>
					<PaginationItem>
						<PaginationLink previous onClick={() => handlePrev()} />
					</PaginationItem>
					{pageCount > 0
						? [...new Array(pageCount)].map((i, index) => (
								<PaginationItem
									active={index + 1 == page ? true : false}
									key={index}>
									<PaginationLink
										onClick={() => handlePage(index + 1)}>
										{index + 1}
									</PaginationLink>
								</PaginationItem>
						  ))
						: null}
					<PaginationItem>
						<PaginationLink next onClick={() => handleNext()} />
					</PaginationItem>
				</Pagination>
			</Row>
			<Modal isOpen={open} toggle={() => toggle()} centered>
				<ModalHeader toggle={() => toggle()}>
					Delete translation
				</ModalHeader>
				<ModalBody className='text-center m-3'>
					<p className='mb-0'>
						Are you sure want to delete this translation?
					</p>
				</ModalBody>
				<ModalFooter>
					<Button color='secondary' onClick={() => toggle()}>
						Cancel
					</Button>{' '}
					<Button color='danger' onClick={() => handleConfirm()}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
		</Container>
	);
};

export default Translations;
